import { router } from '../../router.js'
import Toast from '../../components/Toast/toast.js';
const ws = {
  namespaced: true,
  state: {
    // websocket地址
    wsUrl: process.env.VUE_APP_API_WS || `wss://${window.location.host}/ws`,
    // wsUrl: "wss://im.abc123.me/ws",
    // ws状态
    wsStatus: true,// true:正在连接 false: 已连接成功
    // websocket相关
    socketObj: "", // websocket实例对象
    //心跳检测
    timeout: 10000, // 超时时间
    timeoutObj: null, // 计时器对象——向后端发送心跳检测
    serverTimeoutObj: null, // 计时器对象——等待后端心跳检测的回复
    socketReconnectTimer: null, // 计时器对象——重连
    socketReconnectLock: false, // WebSocket重连的锁
    socketLeaveFlag: false, // 离开标记（解决 退出登录再登录 时出现的 多次相同推送 问题，出现的本质是多次建立了WebSocket连接）

    redEnvelopeInfo: { activeRedEnvelope: {}, list: [] },// 红包对象
    systemSettingsList: [], // 系统配置信息
    messageShow: false,
    messageData: [],
  },

  mutations: {
  },

  actions: {
    // websocket启动
    createWebSocket({ commit, state, dispatch }) {
      let webSocketLink = state.wsUrl; // webSocket地址
      // console.log(webSocketLink);
      try {
        if ("WebSocket" in window) {
          state.socketObj = new WebSocket(webSocketLink);
        } else if ("MozWebSocket" in window) {
          state.socketObj = new MozWebSocket(webSocketLink);
        }
        // websocket事件绑定
        dispatch('socketEventBind');
      } catch (e) {
        console.log("catch" + e);
        // websocket重连
        dispatch('socketReconnect');
      }
    },
    // websocket事件绑定
    socketEventBind({ commit, state, dispatch }) {
      // 连接成功建立的回调
      state.socketObj.onopen = function (event) {
        console.log("WebSocket:succeed");
        state.wsStatus = false; //已连接
        // 功能开关
        dispatch('getSystemSettingsList');
        if(sessionStorage.getItem('sessionId')) {
          dispatch('denglu');
        }
        // 心跳检测重置
        dispatch('start');
      };
      // 连接发生错误的回调
      state.socketObj.onerror = function (event) {
        console.log("WebSocket:error");
        // websocket重连
        dispatch('socketReconnect');
      };
      // 连接关闭的回调
      state.socketObj.onclose = function (event) {
        console.log("WebSocket:close");
        // 心跳检测重置
        dispatch('reset');
        if (!state.socketObj) {
          // 没有离开——重连
          // websocket重连
          dispatch('socketReconnect');
        }
      };
      // 向后端发送数据的回调
      state.socketObj.onsend = function () {
        // console.log("WebSocket:发送信息给后端");
      };
      // 接收到消息的回调
      state.socketObj.onmessage = function (msg) {
        let data = JSON.parse(msg.data);
        if (data.protocol === 'pong') {
          state.serverTime = data.data;
          // 心跳回复——心跳检测重置
          // 收到心跳检测回复就说明连接正常
          // console.log("收到心跳检测回复");
          // 心跳检测重置
          dispatch('start');
        } else {
          // 普通推送——正常处理
          dispatch('SuccessMessage', data);
          // console.log("收到推送消息");
        }
      };
      //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      window.onbeforeunload = () => {
        state.socketObj.close();
      };
    },
    // 处理信息
    SuccessMessage({ commit, state, dispatch, rootState }, data) {
      // 相关处理
      if (data.protocol === 'notFound') {
        console.log('err', data);
      } else {
        if (data.protocol === 'verifySuccess') { // im登录成功
          dispatch('getrRedEnvelope');
        } else if (data.protocol === 'S2C_RED_ENVELOPE_INFO') {
          state.redEnvelopeInfo = data.data.data;
        } else if (data.protocol === 'S2C_SYSTEM_SETTING_INFO') {
          state.systemSettingsList = data.data.data;
        } else if(data.protocol === 'siteMessage') {
          // 弹窗站内信
          state.messageShow = true;
          state.messageData = JSON.parse(data.data);
        }
        // console.log('成功', data);
      }
    },
    // websocket重连
    socketReconnect({ commit, state, dispatch }) {
      if (state.socketReconnectLock) {
        return;
      }
      state.wsStatus = true; //重连中...
      state.socketReconnectLock = true;
      state.socketReconnectTimer && clearTimeout(state.socketReconnectTimer);
      state.socketReconnectTimer = setTimeout(() => {
        console.log("WebSocket:reconnect...");
        state.socketReconnectLock = false;
        // websocket启动
        dispatch('createWebSocket');
      }, 4000);
    },
    // 心跳检测重置
    reset: function ({ commit, state, dispatch }) {
      clearTimeout(state.timeoutObj);
      clearTimeout(state.serverTimeoutObj);
    },
    // 心跳检测启动
    start: function ({ commit, state, dispatch }) {
      clearTimeout(state.timeoutObj);
      clearTimeout(state.serverTimeoutObj);
      state.timeoutObj && clearTimeout(state.timeoutObj);
      state.serverTimeoutObj && clearTimeout(state.serverTimeoutObj);
      state.timeoutObj = setTimeout(() => {
        // 这里向后端发送一个心跳检测，后端收到后，会返回一个心跳回复
        dispatch('onwsSend', { 'protocol': 'ping' });
        // 功能开关
        dispatch('getSystemSettingsList');
        // 获取红包配置
        dispatch('getrRedEnvelope');
        // console.log("发送心跳检测");
        state.serverTimeoutObj = setTimeout(() => {
          // 如果超过一定时间还没重置计时器，说明websocket与后端断开了
          // console.log("未收到心跳检测回复");
          // 关闭WebSocket
          state.socketObj.close();
        }, state.timeout);
      }, state.timeout);
    },
    onwsSend({ commit, state, dispatch }, data) {
      state.socketObj.send(JSON.stringify(data));
    },
    denglu({ commit, state, dispatch }) {
      // 给服务器发送一个字符串:
      let data = {
        protocol: "connect",
        token: sessionStorage.getItem('sessionId'),
      };
      dispatch('onwsSend', data);
    },
    // 获取红包配置
    getrRedEnvelope({ commit, state, dispatch }) {
      let data = {
        protocol: "C2S_RED_ENVELOPE_INFO",
      };
      dispatch('onwsSend', data);
    },
    // 获取系统设置
    getSystemSettingsList({ commit, state, dispatch }) {
      let data = {
        protocol: "C2S_SYSTEM_SETTING_INFO",
      };
      dispatch('onwsSend', data);
    },
    // 关闭ws
    socketObjClose({ commit, state, dispatch }) {
      if(state.socketObj){
        // 心跳检测关闭
        dispatch('reset');
        state.socketObj.close();
        state.socketObj = null;
      }
    },
  }
}

export default ws
